import styled from "styled-components";
import BackgroundImage from "gatsby-background-image";
import Img from "gatsby-image";
import { Link } from "gatsby";

export const Container = styled.div`
  padding: 2rem 1rem 3rem;
  background: rgb(248, 146, 130);
  background: linear-gradient(
    145deg,
    rgb(248, 146, 130) 0%,
    rgb(254, 199, 108) 100%
  );

  @media (min-width: 1024px) {
    padding: 10rem 1rem 3rem;
  }
`;
export const ButtonContainer = styled.div`
  position: relative;
  display: grid;
  grid: 1fr 1fr / 1fr;
  max-width: 1280px;
  margin: 0 auto;
  gap: 1rem;

  @media (min-width: 1024px) {
    grid: auto / 1fr 1fr;
    gap: 4rem;
  }
`;

export const Button = styled.a`
  position: relative;
  display: flex;
  border-radius: 1.625rem;
  overflow: hidden;
  width: 100%;
  text-decoration: none;
`;

export const ButtonImage = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: brightness(1);
  transition: 0.2s;
  ${Button}:hover & {
    filter: brightness(.8);
    transform: scale(1.1);
  }
  
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
`;

export const ButtonContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 100%;
`;

export const ButtonTitleContainer = styled.div`
  position: relative;
  padding: 5rem 0;

  @media (min-width: 1024px) {
    padding: 7rem 0;
  }
`;

export const ButtonTitle = styled.div`
  font-family: proxima-nova, sans-serif;
  font-weight: 700;
  font-style: normal;
  color: #fff;
  font-size: 2.5rem;
  visibility: visible;
  ${Button}:hover & {
    visibility: hidden;
  }

  @media (min-width: 1024px) {
    font-size: 3rem;
  }
`;

export const ButtonSubtitle = styled(ButtonTitle)`
  font-size: 1rem;
  font-weight: 400;
  visibility: visible;
  ${Button}:hover & {
    visibility: hidden;
  }

  @media (min-width: 1024px) {
    font-size: 1.125rem;
  }
`;

export const AfterHoverTitle = styled(ButtonTitle)`
  font-size: 2rem;
  text-decoration: underline;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  visibility: hidden;
  ${Button}:hover & {
    visibility: visible;
  }
`;

export const FooterContainer = styled.footer`
  position: relative;
  display: grid;
  justify-content: center;
  z-index: 1;
  pointer-events: none;
  margin-top: 4rem;

  @media (min-width: 1024px) {
    margin-top: 10rem;
  }
`;

export const SocialIconsContainer = styled.div`
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  display: grid;
  grid: 1fr / 1fr 1fr 1fr 1fr;
  pointer-events: auto;
  width: 10rem;
  justify-content: center;
  justify-items: center;
  grid-gap: 1vw;
`;

export const SocialIconLink = styled.a`
  margin-bottom: 1rem;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SocialIconImage = styled(Img)`
  width: 100%;
  display: block;
  position: relative;
  text-align: center;
  cursor: pointer;
`;

export const LabelText = styled.div`
  position: relative;
  text-align: center;
  padding-bottom: 2rem;
`;
