import React from "react";
import {
  Container,
  Content,
  Header,
  Text,
  HeaderContainer,
  StyledImage,
  Button,
  ImageColumn, ButtonForm, ButtonContainer, AboutContainer,
} from "./styles";
import {graphql, StaticQuery} from "gatsby";
import BackgroundImage from "gatsby-background-image";
import Img from "gatsby-image";

export const AboutGroups = () => {
  return (
    <StaticQuery
      query={graphql`
        query AboutQuery {
          aboutImage: file(relativePath: { eq: "Foto2.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          arrow: file(relativePath: { eq: "arrow.png" }) {
            childImageSharp {
              fluid(maxWidth: 100, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          arrow2: file(relativePath: { eq: "arrow2.png" }) {
            childImageSharp {
              fluid(maxWidth: 100, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={(data) => (
        <Container>
          <Content>
            <AboutContainer>
              <HeaderContainer>
                <Header>Czym są Grupy?</Header>
              </HeaderContainer>
              <Text>
                Grupy Radości to małe spotkania do 10 osób, które odbywają się
                na terenie Trójmiasta lub online. Ludzie spędzają razem czas,
                aby omówić niedzielne kazanie, porozmawiać o życiu, wspólnie
                modlić i wspierać. Spotkania są organizowane regularnie co
                tydzień lub dwa w każdym sezonie.
              </Text>
              <ButtonContainer>
                <Button to={'/grupy'}>
                  Znajdź grupę dla siebie
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                    <path fill="none" d="M0 0h24v24H0z"/>
                    <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z"
                          fill="rgba(255,255,255,1)"/>
                  </svg>
                </Button>
                <Button target={"_blank"} href={"https://forms.gle/wPeUBLYkQAzbz5n3A"}>
                  Otwórz nową grupę
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                    <path fill="none" d="M0 0h24v24H0z"/>
                    <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z"
                          fill="rgba(255,255,255,1)"/>
                  </svg>
                </Button>
              </ButtonContainer>
            </AboutContainer>
            <ImageColumn>
              <StyledImage fluid={data.aboutImage.childImageSharp.fluid}/>
            </ImageColumn>
          </Content>
        </Container>
      )}
    />
  );
};
