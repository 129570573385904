import * as React from "react";
import HomeHero from "../components/HomeHero";
import { AboutGroups } from "./../components/AboutGroups";
import { Contact } from "./../components/Contact";
import Slogan from "../components/Slogan";
import { Helmet } from "react-helmet";
import "normalize.css";
import "../styles/main.css";

const IndexPage = ({ data }) => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Grupy Radości - Kościół Radość Życia</title>
        <link rel="stylesheet" href="https://use.typekit.net/rjh5hkz.css" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="http://grupy.kzgdansk.org/" />
        <meta property="og:title" content="Grupy Radości" />
        <meta property="og:description" content="Łączymy ludzi, dzieląc życie" />
        <meta property="og:image" content="/grupy-radosci-preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="http://grupy.kzgdansk.org/" />
        <meta property="twitter:title" content="Grupy Radości" />
        <meta property="twitter:description" content="Łączymy ludzi, dzieląc życie" />
        <meta property="twitter:image" content="/grupy-radosci-preview.png" />
      </Helmet>
      <main>
        <HomeHero data={data} />
        <AboutGroups />
        <Slogan data={data} />
        <Contact data={data} />
      </main>
    </React.Fragment>
  );
};

export default IndexPage;
