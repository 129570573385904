import styled from "styled-components";
import Img from "gatsby-image";

export const SloganContainer = styled.div`
  position: relative;
  width: 100%;
  background-color: #f86685;
  padding: 8rem 2rem;
`;
export const SloganImageContainer = styled.div`
  max-width: 1920px;
  margin: 0 auto;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  left: 50%;
  transform: translateX(-50%);
`;

export const SloganImage = styled(Img)`
  height: 100%;
  object-fit: cover;
`;

export const Title = styled.div`
  position: relative;
  text-align: center;
  width: 100%;
  font-family: proxima-nova, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 2.5rem;
  line-height: 100%;
  color: #ffffff;
  z-index: 3;
  -webkit-text-fill-color: rgba(0, 0, 0, 0);
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #ffffff;
  max-width: 1280px;
  margin: 0 auto;
  
  @media (min-width: 768px) {
    font-size: 4rem;
  }
  
  @media (min-width: 1024px) {
    font-size: 6.2rem;
    -webkit-text-stroke-width: 3px;
  }
  
  @media (min-width: 1280px) {
    font-size: 8.4rem;
  }
`;

export const TitlePart1 = styled.div`
  @media (min-width: 768px) {
    text-align: left;
  }
`;

export const TitlePart2 = styled.div``;

export const TitlePart3 = styled.div`
  @media (min-width: 768px) {
    text-align: right;
  }
`;
