import styled from "styled-components";
import Img from "gatsby-image";
import { Button } from "../AboutGroups/styles";
import {Link} from "gatsby";

export const HeroContainer = styled.div`
  width: 100%;
  background-color: #fec76c;
  padding-bottom: 3rem;
  margin-bottom: -18rem;
  
  @media (min-width: 768px) {
    margin-bottom: 0;
    padding-bottom: 0;
  }
`;

export const IconContainer = styled.div`
  position: absolute;
`;

export const IconLink = styled.a``;

export const IconImage = styled(Img)`
  position: relative;
  top: 3vw;
  left: 3vw;
  width: 9vw;
  object-fit: contain;
  cursor: pointer;
  @media (min-width: 768px) {
    width: 6vw;
  }
`;

export const MainButtonContainer = styled(Link)`
  position: fixed;
  top: 1rem;
  right: 1rem;
  padding: .6rem 1rem;
  background-color: #fff;
  border-radius: .3rem;
  color: #FEC76C;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.05);
  z-index: 999;

  @media (min-width: 768px) {
    top: 2rem;
    right: 2rem;
    padding: .8rem 1.3rem;
  }
`;

export const MainButton = styled.div`
  z-index: 99;
  font-size: 1rem;
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 768px) {
    font-size: 1.2rem;
  }
`;

export const HeroImageContainer = styled.div`
  width: 100%;
  max-width: 120rem;
  margin: 0 auto;
  top: -18rem;
  z-index: 10;
  position: relative;

  @media (min-width: 768px) {
    top: auto;
  }
`;

export const HeroImage = styled(Img)`
  height: 100%;
  object-fit: contain;
`;

export const Content = styled.div`
  width: 90%;
  padding: 15vh 1rem 5rem;
  max-width: 120rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 20;

  @media (min-width: 768px) {
    padding-bottom: 0;
  }
`;

export const HeaderH1 = styled.h1`
  font-size: 5rem;
  text-align: center;
  padding: 0;
  margin: 0;
  color: #fff;
  font-weight: 800;
  text-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
  
  @media (min-width: 1280px) {
    font-size: 10rem;
  }
`;

export const HeaderH2 = styled.h2`
  font-size: 1.5rem;
  text-align: center;
  padding: 0;
  margin: 0 0 3rem;
  color: #fff;
  font-weight: 700;
  text-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
  position: relative;

  @media (min-width: 768px) {
    font-size: 2.2rem;
  }

  &::before {
    content: "";
    display: none;
    position: absolute;
    width: .8rem;
    height: .8rem;
    border: 4px solid #fff;
    top: 50%;
    left: -3.5rem;
    transform: translateY(-50%) rotate(45deg);
    animation: rotation2 6s linear infinite;
    
    @media (min-width: 768px) {
      display: block;
    }
  }

  &::after {
    content: "";
    display: none;
    position: absolute;
    width: .8rem;
    height: .8rem;
    border: 4px solid #fff;
    top: 50%;
    right: -3.5rem;
    transform: translateY(-50%) rotate(45deg);
    animation: rotation 6s linear infinite;
    
    @media (min-width: 768px) {
      display: block;
    }
  }

  @keyframes rotation {
    from {
      transform: translateY(-50%) rotate(0deg);
    }
    to {
      transform: translateY(-50%) rotate(359deg);
    }
  }

  @keyframes rotation2 {
    from {
      transform: translateY(-50%) rotate(359deg);
    }
    to {
      transform: translateY(-50%) rotate(0deg);
    }
  }
`;
