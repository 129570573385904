import React from "react";
import {
  Container,
  ButtonContainer,
  Button,
  ButtonImage,
  ButtonContent,
  ButtonTitleContainer,
  ButtonTitle,
  ButtonSubtitle,
  AfterHoverTitle,
  FooterContainer,
  SocialIconsContainer,
  SocialIconLink,
  SocialIconImage,
  LabelText,
} from "./styles";
import { graphql, StaticQuery } from "gatsby";
import Img from "gatsby-image";

export const Contact = () => {
  return (
    <StaticQuery
      query={graphql`
        query ContactQuery {
          button1: file(relativePath: { eq: "FotoKosciol.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 1200, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          button2: file(relativePath: { eq: "FotoEwa.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 1200, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          yt: file(relativePath: { eq: "Logo YT.png" }) {
            childImageSharp {
              fluid(maxWidth: 100, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          insta: file(relativePath: { eq: "Logo Insta.png" }) {
            childImageSharp {
              fluid(maxWidth: 100, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          fb: file(relativePath: { eq: "Logo FB.png" }) {
            childImageSharp {
              fluid(maxWidth: 100, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          spotify: file(relativePath: { eq: "Logo Spotify.png" }) {
            childImageSharp {
              fluid(maxWidth: 100, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={(data) => (
        <Container>
          <ButtonContainer>
            <Button target="_blank" href={"http://kzgdansk.org/kosciol/o-nas"}>
              <ButtonImage>
                <Img fluid={data.button1.childImageSharp.fluid} />
              </ButtonImage>
              <ButtonContent>
                <ButtonTitleContainer>
                  <AfterHoverTitle>Kliknij</AfterHoverTitle>
                  <ButtonTitle>Kim jesteśmy?</ButtonTitle>
                  <ButtonSubtitle>
                    Chcesz dowiedzieć się więcej o naszym kościele?
                  </ButtonSubtitle>
                </ButtonTitleContainer>
              </ButtonContent>
            </Button>
            <Button target="_blank" href={"http://kzgdansk.org/dla-goscia"}>
              <ButtonImage>
                <Img fluid={data.button2.childImageSharp.fluid} />
              </ButtonImage>
              <ButtonContent>
                <ButtonTitleContainer>
                  <AfterHoverTitle>Pogadajmy</AfterHoverTitle>
                  <ButtonTitle>Masz pytanie?</ButtonTitle>
                  <ButtonSubtitle>
                    Pytania to normalna rzecz. <br />
                    Chętnie odpowiemy na każde z nich. <br />
                    Po prostu wyślij nam wiadomość!{" "}
                  </ButtonSubtitle>
                </ButtonTitleContainer>
              </ButtonContent>
            </Button>
          </ButtonContainer>
          <FooterContainer>
            <SocialIconsContainer>
              <SocialIconLink
                target="_blank"
                href="https://www.facebook.com/radosczyciakosciol"
              >
                <SocialIconImage fluid={data.fb.childImageSharp.fluid} />
              </SocialIconLink>
              <SocialIconLink
                target="_blank"
                href="https://www.instagram.com/radosczyciakosciol/"
              >
                <SocialIconImage fluid={data.insta.childImageSharp.fluid} />
              </SocialIconLink>
              <SocialIconLink
                target="_blank"
                href="https://www.youtube.com/channel/UC4Ig79dWDGcMUDugzYpMUiA"
              >
                <SocialIconImage fluid={data.yt.childImageSharp.fluid} />
              </SocialIconLink>
              <SocialIconLink
                target="_blank"
                href="https://open.spotify.com/show/3DUuz1nPsP2ANL2wVdNcBW"
              >
                <SocialIconImage fluid={data.spotify.childImageSharp.fluid} />
              </SocialIconLink>
            </SocialIconsContainer>
            <LabelText>Kościół Radość Życia 2021</LabelText>
          </FooterContainer>
        </Container>
      )}
    />
  );
};
