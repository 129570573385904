import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import {
  HeroContainer,
  HeroImageContainer,
  HeroImage,
  Content,
  HeaderH1,
  HeaderH2,
  IconContainer,
  IconImage,
  MainButton,
  IconLink, MainButtonContainer,
} from "./styles";
import { Button } from "../AboutGroups/styles";
import { SocialIconLink } from "../Contact/styles";

export default ({ data }) => {
  return (
    <StaticQuery
      query={graphql`
        query HeadingQuery {
          file(relativePath: { eq: "homeHeader.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          icon: file(relativePath: { eq: "icon.png" }) {
            childImageSharp {
              fluid(maxWidth: 300, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          arrow: file(relativePath: { eq: "arrow.png" }) {
            childImageSharp {
              fluid(maxWidth: 100, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          arrow2: file(relativePath: { eq: "arrow2.png" }) {
            childImageSharp {
              fluid(maxWidth: 100, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={(data) => (
        <HeroContainer>
          <IconContainer>
            <IconLink target="_blank" href="http://kzgdansk.org/">
              <IconImage fluid={data.icon.childImageSharp.fluid} />
            </IconLink>
          </IconContainer>
          <Content>
            <HeaderH2>łączymy ludzi, dzieląc życie</HeaderH2>
            <HeaderH1>Grupy Radości</HeaderH1>
          </Content>
          <HeroImageContainer>
            <HeroImage fluid={data.file.childImageSharp.fluid} />
          </HeroImageContainer>
          <MainButtonContainer to={"/grupy"}>
            <MainButton>
              Dołącz
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                <path fill="none" d="M0 0h24v24H0z"/>
                <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z"
                      fill="rgba(254, 199, 108, 1)"/>
              </svg>
            </MainButton>
          </MainButtonContainer>
        </HeroContainer>
      )}
    />
  );
};
