import styled from "styled-components";
import BackgroundImage from "gatsby-background-image";
import Img from "gatsby-image";
import { Link } from "gatsby";

export const Container = styled.div`
  background: rgb(244, 115, 142);
  background: linear-gradient(
    145deg,
    rgb(244, 115, 142) 0%,
    rgb(252, 182, 116) 100%
  );
`;
export const Content = styled.div`
  width: 90%;
  margin: 0 auto;
  max-width: 92.5rem;
  padding: 0 1rem 2rem;
  display: grid;
  grid: auto / 1fr;
  align-items: center;

  @media (min-width: 1024px) {
    grid: auto / 1fr .8fr;
  }

  @media (min-width: 1280px) {
    grid: auto / 1fr 1.2fr;
    padding: 0 1rem 10rem;
  }
`;

export const HeaderContainer = styled.div`
  margin-top: 4rem;
  z-index: 30;
  position: relative;

  @media (min-width: 1024px) {
    margin-top: -65vh;
    min-height: calc(300px + 65vh);
    height: 100vh;
  }
`;

export const Header = styled.h2`
  font-size: 4rem;
  padding: 0;
  margin: 0;
  font-weight: 700;
  text-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
  line-height: 0.9;

  @media (min-width: 1024px) {
    font-size: 7.5rem;
    position: sticky;
    top: 60vh;
  }
`;

export const ImageColumn = styled.div`
  position: relative;
`;

export const Text = styled.p`
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 4rem;

  @media (min-width: 1024px) {
    font-size: 1.25rem;
  }
`;

export const ButtonContainer = styled.div`
  display: grid;
  grid: 1fr 1fr / auto;
`;

export const Button = styled(Link)`
  font-size: 1.1rem;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #fff;
  padding: .5rem 1rem;
  width: 100%;
  color: #fff;
  text-decoration: none;
  margin-bottom: 1rem;

  @media (min-width: 1024px) {
    min-width: unset;
    font-size: 1.4rem;
    padding: .85rem 1.5rem;
  }
  
  svg {

    @media (min-width: 1024px) {
      margin-left: .3rem;
    }
  }

  &:hover {
    border-color: #d03891;
    color: #d03891;
    
    svg path:nth-child(2) {
      fill: #d03891;
    }
  }

  span {
    display: block;
    position: relative;
    margin-left: 1rem;
  }
`;
export const StyledImage = styled(Img)`
  mix-blend-mode: multiply;
`;

export const ButtonForm = styled.a`
  font-size: 2rem;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 28rem;
  padding: 1rem;
  border: 3px solid #fff;
  width: fit-content;
  color: #fff;
  text-decoration: none;
  margin-bottom: 1rem;

  @media (max-width: 70rem) {
    min-width: unset;
    font-size: 1.5rem;
    padding: 1rem;
  }

  span.hover {
    display: none;
  }

  &:hover {
    border-color: #d03891;
    color: #d03891;
    span.normal {
      display: none;
    }

    span.hover {
      display: unset;
    }
  }

  span {
    width: 25px;
    height: 15px;
    display: block;
    position: relative;
    margin-left: 1rem;
  }
`;

export const AboutContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;


  @media (min-width: 1024px) {
    align-items: flex-start;
  }
`
