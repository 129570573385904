import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import {
  SloganContainer,
  SloganImageContainer,
  SloganImage,
  Title,
  TitlePart1,
  TitlePart2,
  TitlePart3,
} from "./styles";

export default ({ data }) => {
  return (
    <StaticQuery
      query={graphql`
        query SloganQuery {
          file(relativePath: { eq: "FotoTlum.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={(data) => (
        <SloganContainer>
          <SloganImageContainer>
            <SloganImage fluid={data.file.childImageSharp.fluid} />
          </SloganImageContainer>
          <Title>
            <TitlePart1>Grupy Radości</TitlePart1>
            <TitlePart2>zmieniają tłum</TitlePart2>
            <TitlePart3>w społeczność.</TitlePart3>
          </Title>
        </SloganContainer>
      )}
    />
  );
};
